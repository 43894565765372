import React from "react"
import StatusComponent from "./statusComponent"
import SvgStatus from "/src/assets/svgComponents/status.svg"

const StatusLayout = () => {
  return (
    <div>
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content text-left">
            <div className="row">
              <div className="status-info col-lg-6 order-lg-1 col-12 order-2">
                <h1 className="mb-4 h1-title">Status servicii</h1>
                <StatusComponent />
              </div>
              <div className="col-lg-6 order-lg-2 col-12 order-1 text-lg-left text-center">
                <SvgStatus className='status-svg'/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatusLayout
